/* fichier JS de base commun quelques soient les versions du projet web */

// charge bootstrap nécessite jquery et popper
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/util'
// import 'bootstrap' // directement déclaré dans le fichier de config webpack base
/* global lazySizes */
import 'lazysizes'

// pas de ./ car on résout dans theme donc déjà déclaré dans resolve.modules dans webpack.base.js
// l'extension n'est pas obligatoire car connue dans resolve.extensions, ne fonctionnera pas si il y a des fichiers de différentes extensions portant le même nom (ex: bootstrap.css et bootstrap.js dans le même dossier)
// import 'bootstrap/scss/bootstrap'
import './scss/_bootstrap_custom'

// Styles custom
import './scss/custom'

// Plugins template
import 'js/navigation.js'
import 'js/navigation.fixed.js'

// Scripts librairies
import { extendClick } from './js/libs/utils'
// import './js/components/cookie.js'
lazySizes.cfg.expand = 80
lazySizes.cfg.expFactor = 1
$('body').removeClass('no-js')

// lazysizes : add simple support for background images :
document.addEventListener('lazybeforeunveil', function (e) {
  const bg = e.target.getAttribute('data-bg')
  if (bg) e.target.style.backgroundImage = 'url(' + bg + ')'
})

// fonction sur le resize
function handleResize () {
  $('#main-logo').css('display', 'none')
  $('#light-logo').css('display', 'none')
  if ($(window).width() > 991) {
    $('#light-logo').css('display', 'inline-block')
    handleNav()
  } else {
    $('#main-logo').css('display', 'inline-block')
  }
}

// fonction sur la navigation
function handleNav () {
  if ($(window).width() > 991) {
    if ($(window).scrollTop() > 30) {
      $('.navigation-fixed-wrapper').addClass('nav-white-bg')
      $('#main-logo').css('display', 'inline-block')
      $('#light-logo').css('display', 'none')
    } else {
      $('.navigation-fixed-wrapper').removeClass('nav-white-bg')
      $('#main-logo').css('display', 'none')
      $('#light-logo').css('display', 'inline-block')
    }
  }
}

// Utils
function getScrollToTop () {
  import('./js/libs/scrollToTop/scrollToTop.js').then(() => {
    $.scrollUp({
      easingType: 'linear',
      topSpeed: 300,
      scrollSpeed: 400,
      animation: 'fade',
      scrollText: '<i class="icon-chevron-up"></i>'
    })
  })
}

// Actions principales communes à toutes les pages
$(document).ready(function () {
  $('#navigation4').navigation({ overlayColor: 'rgba(0,0,0,.6)' }).fixed({ offset: 20 })

  handleResize()
  $(window).resize(function () {
    handleResize()
  })

  $(window).scroll(function () {
    handleNav()
    if ($(window).scrollTop() >= 150) {
      if (!$('#scrollUp').length) getScrollToTop()
    }
  })

  // boutons de partage
  $('button[data-toggle="share"]').on('click', function () {
    window.open($(this).attr('data-href'))
  })

  // formulaire de recherche des offres
  $('form[name="offers_search_form"]').on('submit', function () {
    let action = $(this).find('select[name="category"]').val()
    if ($(this).find('input[name="type"]').val() !== '') { action += '/' + $(this).find('input[name="slug"]').val() }
    $(this).attr('action', action)
  })

  extendClick()
})
