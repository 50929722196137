// Charge la base commune
import './common.js'

// Scripts librairies
/* global Waypoint */
import 'waypoints/lib/jquery.waypoints.min.js'

// rafraîchissement
function r () {
  Waypoint.refreshAll()
}

// OwlCarousel
function getOwlCarousel () {
  import(/* webpackChunkName: "owl-carousel" */ './js/components/owl-carousel.js').then(() => {
    r()
  })
}

// Cities
function getSearchOffers () {
  import('./js/components/offers-search-form.js').then(module => {
    module.offersSearchForm.init($('form[name="offers_search_form"]'))
    r()
  })
}

// Components
function getContactForm () {
  const $f = $('form[name="contact_form"]')
  $f.find('button, input, textarea, select').prop('disabled', true).css({ opacity: 0.5 })
  import('./js/components/contact-form.js').then(() => {
    $f.find('button, input, textarea, select').prop('disabled', false).css({ opacity: 1 })
  })
}

// actions principales communes à toutes les pages
$(document).ready(function () {
  let $f
  // gestion des carousel
  $f = $('div.owl-carousel')
  if ($f.length) {
    $f.waypoint(function () {
      getOwlCarousel()
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion du formulaire de contact
  $f = $('form.contact-form')
  if ($f.length) {
    $f.waypoint(function () {
      getContactForm()
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // refresh waypoints au chargement d'une image
  $('.lazyload').on('lazyloaded', () => {
    r()
  })
  setTimeout(function () {
    r()
  }, 500)

  // components
  $('form[name="offers_search_form"] input[name="search"]').on('click', function () {
    $(this).blur().attr('disabled', '')
    getSearchOffers()
    $(this).off('click')
  })
})
